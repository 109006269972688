import PointerInteraction from 'ol/interaction/Pointer'
import {Interaction} from 'ol/interaction';
import {altKeyOnly} from 'ol/events/condition';
import {Control} from 'ol/control';
import Kinetic from 'ol/Kinetic';
import { easeOut } from 'ol/easing';
import Geocoder from "ol-geocoder";
import Overlay from "ol/Overlay"
import {getLength} from 'ol/sphere';
import {GPX, WKT, GeoJSON, IGC, KML, TopoJSON, Polyline} from 'ol/format';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {DragAndDrop} from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import {all_layers, setTopOpacity, updatePermalink, top_opacity} from './state.js'
import {bindLayerListeners, unbindLayerListeners, isMobile, formatLength} from './util.js'
import {utils} from "./geometry.js"

export class DoubleTapZoom extends PointerInteraction {

  constructor(opt_options) {
    const options = opt_options ? opt_options : {};

    super(options);

    this.duration_ = options.duration !== undefined ? options.duration : 600;


    this.touch_begin = null

    this.startTime = 0

    this.handlingDownUpSequence = false

    this.active = false
    this.kinetic_ = new Kinetic(-0.005, 0.05, 100)
  }

  isActive() {
    return this.active
  }

  calcNewResolution(mapBrowserEvent, initialRes, movedDistance) {
    const map = mapBrowserEvent.map;
    const view = map.getView();

    this.kinetic_.update(0, movedDistance);
    const movedPercent = Math.abs(movedDistance) / map.getSize()[1];
    const scalePercent = 1.0 + movedPercent * 3;

    const dirScale = Math.pow((movedDistance > 0.0 ? scalePercent : 1.0 / scalePercent),2)

    var newScale = initialRes * dirScale;

    newScale = Math.max(newScale, view.getMinResolution())
    newScale = Math.min(newScale, view.getMaxResolution())
    return newScale;
  }

  handleMoveEvent(mapBrowserEvent) {
    if (this.touch_begin == null) {
      return false
    }
    var dx = this.touch_begin.clientX - mapBrowserEvent.originalEvent.clientX;
    var dy = this.touch_begin.clientY - mapBrowserEvent.originalEvent.clientY
    if (Math.hypot(dx, dy) > 30) {
      this.startTime = 0
    }
  }

  handleDragEvent(mapBrowserEvent) {
    if (this.targetPointers.length > 1) {
      return false
    }
    var now = new Date()
    const map = mapBrowserEvent.map;
    const view = map.getView();

    const touch1 = mapBrowserEvent.originalEvent;
    const dy = this.touch_begin.clientY - touch1.clientY;
    var newScale = this.calcNewResolution(mapBrowserEvent, this.initialScale, dy)

    view.setResolution(newScale);
    return true
  }

  handleUpEvent(mapBrowserEvent) {
    const map = mapBrowserEvent.map;
    const view = map.getView();

    if (this.kinetic_.end()) {
      var distance = this.kinetic_.getDistance();
      var angle = this.kinetic_.getAngle();
      var movedDistance = distance * Math.sin(angle)
      var res = this.calcNewResolution(mapBrowserEvent, view.getResolution(), movedDistance)
      res = view.getConstrainedResolution(res)
      view.animate({resolution: res, easing: easeOut, duration: this.duration_});
    }
    view.endInteraction();

    this.touch_begin = null
    var self = this
    setTimeout(function(){self.active = false;}, 400)
    return true;
  }

  handleDownEvent(mapBrowserEvent) {
    if (this.targetPointers.length > 1) {
      return false
    }

    this.touch_begin = mapBrowserEvent.originalEvent;
    var now = new Date()

    if (this.startTime != null && now - this.startTime < 250) {
      const map = mapBrowserEvent.map;
      this.active = true;
      this.kinetic_.begin();
      this.initialScale = map.getView().getResolution();
      this.touch_begin = mapBrowserEvent.originalEvent;

      if (!this.handlingDownUpSequence) {
        map.getView().beginInteraction();
      }
      return true;
    }

    this.startTime = now
    return false
  }
}

const G_KEY = 71;
const E_KEY = 69;
const T_KEY = 84;
const X_KEY = 88;
const D_KEY = 68;
const C_KEY = 67;
const S_KEY = 83;
const I_KEY = 73;
const K_KEY = 75;
const L_KEY = 76;
const F_KEY = 70;
const J_KEY = 74;
const O_KEY = 79;
const P_KEY = 80;
const A_KEY = 65;
const M_KEY = 77;
const Z_KEY = 90;
const ESC_KEY = 27;
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

var old_opacity = 1.0;

export var addKeyboardControls = function(map) {
  var key_down = null
  var mouse_coord = null;
  const dialog = document.getElementById('infoDialog');
  dialog.addEventListener('click', function (event) {
    var rect = dialog.getBoundingClientRect();
    var isInDialog=(rect.top <= event.clientY && event.clientY <= rect.top + rect.height
      && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (!isInDialog) {
        dialog.close();
    }
  });
  document.addEventListener('keydown', function(evt) {
    var gcdtext = document.getElementById('gcd-input-query');
    if (gcdtext === document.activeElement) {
      return;
    }
    var curr_key = evt.which;
    if (evt.which === Z_KEY && !key_down) {
      old_opacity = top_opacity;
      setTopOpacity(0)
      updatePermalink()
      evt.preventDefault();
      map.render();
    } else if (evt.which === L_KEY) {
      if (mouse_coord != null) {
        var coord = utils.to4326(mouse_coord)
        var url = 'https://skraafoto.kortforsyningen.dk/oblivisionjsoff/index.aspx?project=Denmark&lon=' + coord[0] +'&lat=' + coord[1]
        window.open(url,'_blank');
      }
      evt.preventDefault();
    } else if (evt.which === I_KEY) {
      dialog.showModal();
      evt.preventDefault();
    } else if (evt.which === K_KEY) {
      if (mouse_coord != null) {
        var coord = utils.to4326(mouse_coord)
        var url = 'https://www.openstreetmap.org/query?lat=' + coord[1] +'&lon=' + coord[0] + '#map=' + map.getView().getZoom() + '/' + coord[1] +'/' + coord[0]
        window.open(url,'_blank');
      }
      evt.preventDefault();
    } else if (evt.which === J_KEY) {
      if (mouse_coord != null) {
        var coord = utils.to4326(mouse_coord)
        var d = new Date();
        var date = d.getFullYear() + '.' + d.getMonth() + '.' + d.getDate()
        var time = d.getHours() + ':' + d.getMinutes()
        var url = 'http://suncalc.net/#/' + coord[1] + ',' + coord[0] + ',' + map.getView().getZoom() + '/' + date + '/' + time
        window.open(url,'_blank');
      }
      evt.preventDefault();
    } else if (evt.which === M_KEY) {
      if (mouse_coord != null) {
        var coord = utils.to4326(mouse_coord)
        var url = 'https://historiskekort.dk/?kortvaerk=Videnskabernes+Selskab,+koncept&geometri=POINT(' + coord[0] + '+' + coord[1] + ')&pagesize=25&offset=0'
        window.open(url,'_blank');
      }
      evt.preventDefault();
    } else if (evt.which === G_KEY) {
      if (mouse_coord != null) {
        var coord = utils.to4326(mouse_coord)
        var url = "https://www.google.com/maps/@?api=1&map_action=map&center=" + coord[1] + ',' + coord[0] + "&zoom=" + map.getView().getZoom() + "&basemap=satellite"
        // var url = 'https://www.google.dk/maps/@' + coord[1] + ',' + coord[0] + ',' + view.getZoom() + 'z?api=1&map_action=map&basemap=satellite'
        window.open(url,'_blank');
      }
      evt.preventDefault();
    }
    key_down = evt.which
  });

  document.addEventListener('keyup', function(evt) {
    if (evt.which === Z_KEY) {
      setTopOpacity(old_opacity);
      updatePermalink()
      evt.preventDefault();
      map.render();
    }
    key_down = null
  });

  map.on('pointermove', function(evt) {
    if (evt.dragging) {
      return;
    }

    mouse_coord = evt.coordinate;
  });
}

export var MouseWheelLayer = /*@__PURE__*/(function (Interaction) {
  function MouseWheelLayer() {
    Interaction.call(this, {
      handleEvent: this.handleEvent
    });
    this.condition_ = altKeyOnly;
  }

  if ( Interaction ) MouseWheelLayer.__proto__ = Interaction;
  MouseWheelLayer.prototype = Object.create( Interaction && Interaction.prototype );
  MouseWheelLayer.prototype.constructor = MouseWheelLayer;
  MouseWheelLayer.prototype.handleEvent= function handleEvent(mapBrowserEvent) {
    if (!this.condition_(mapBrowserEvent)) {
      return true;
    }
    const type = mapBrowserEvent.type;
    if (type !== 'wheel') {
      return true;
    }

    mapBrowserEvent.preventDefault();

    const map = mapBrowserEvent.map;
    const wheelEvent = /** @type {WheelEvent} */ (mapBrowserEvent.originalEvent);

    var delta = wheelEvent.deltaY;

    if (delta === 0) {
      return false;
    }

    var al = 0
    for (var i = 0; i < all_layers.length; i++) {
      if (all_layers[i].getVisible()) {
        al = i
      }
      all_layers[i].setVisible(false)
    }
    if (delta > 0) {
      al++;
    } else {
      al--;
    }
    if (al >= all_layers.length) {
      al = 0;
    }
    if (al < 0) {
      al = all_layers.length -1;
    }
    all_layers[al].setVisible(true);
    console.log(all_layers[al].get("name"))

    return false;
  }


  return MouseWheelLayer;
}(Interaction));

export var addGeocoder = function(map) {
  var t_type;
  if (isMobile) {
    t_type = 'glass-button'
  } else {
    t_type = 'text-input'
  }

  var geocoder = new Geocoder('nominatim', {
    provider: 'osm',
    // key: '__some_key__',
    lang: 'da-DK', //en-US, fr-FR
    placeholder: 'Search for ...',
    targetType: t_type,
    limit: 5,
    keepOpen: true,
    preventDefault: true
  });
  geocoder.on('addresschosen', function(evt){
    var feature = evt.feature,
        coord = evt.coordinate,
        address = evt.address;
    var resolution = 0.1;
    var duration = 0;
    map.getView().animate({ duration, resolution }, { duration, center: coord });
  });

  map.addControl(geocoder);
}

export class Tooltip {
  // constructor 
  constructor(map)
  {
    var self = this
    this.map = map
    this.tooltip = document.getElementById('tooltip');
    this.tt_overlay = new Overlay({
      element: tooltip,
      offset: [10, 0],
      positioning: 'bottom-left'
    });
    map.addOverlay(this.tt_overlay);

    map.on('pointermove', function(evt) {
      if (evt.dragging) {
        return;
      }

      if (!isMobile) {
        self.displayTooltip(evt);
      }
    });

    map.on('singleclick', function(evt){
      self.hideTooltip()
    });
  }


  displayTooltip(evt, action) {
    var pixel = evt.pixel;
    var feature = this.map.forEachFeatureAtPixel(pixel, function(feature) {
      if (feature.get('type') == 'routepoint') {
        return null
      }
      return feature;
    });
    this.tooltip.style.display = feature ? '' : 'none';
    if (feature) {
      this.tt_overlay.setPosition(evt.coordinate);
      if (feature.get('anlaegsbet') != undefined) {
        this.tooltip.innerHTML = feature.get('anlaegsbet') + ", " + feature.get('datering');
      } else if (feature.get('type') == 'overpass' || feature.get('type') == 'naturbasen' || feature.get('type') == 'udinaturen') {
        var keys = feature.getKeys()
        this.tooltip.innerHTML = "";
        if (feature.get('type') == 'overpass') {
          this.tooltip.innerHTML += "<p><b>name:" + feature.get('name') + "<b/></p>"
        } else if (feature.get('type') == 'naturbasen') {
          this.tooltip.innerHTML += "<p><b>name:" + feature.get('obs_id') + "<b/></p>"
        } else if (feature.get('type') == 'udinaturen') {
          this.tooltip.innerHTML += "<p><b>name:" + feature.get('title') + "<b/></p>"
        }
        for (var i = 0; i < keys.length; i++) {
          if (keys[i] == 'geometry' ||
              keys[i] == 'name' ||
              keys[i] == 'title' ||
              keys[i] == 'type') {
            continue;
          }
          this.tooltip.innerHTML += "<p>" + keys[i] + ":" + feature.get(keys[i]) + "</p>"
        }
        this.tooltip.onclick = action
      } else if (feature.get('name')) {
        this.tooltip.innerHTML = feature.get('name')
      } else if (feature.get('type') == 'route') {
        var length = formatLength(getLength(feature.get('geometry')));
        this.tooltip.innerHTML = length
      } else {
        this.tooltip.style.display = 'none';
      }
    }
  };

  hideTooltip() {
    this.tooltip.style.display = 'none';
  }
}

export class DragAndDropInteraction {
  // constructor 
  constructor()
  {
    this.num_drop = 0
    this.interaction = new DragAndDrop({
      formatConstructors: [
        GPX,
        GeoJSON,
        IGC,
        KML,
        TopoJSON
      ]
    })

    this.colors = ['rgba(255,0,0,0.9)', 'rgba(0,255,0,0.9)', 'rgba(0,0,255,0.9)','rgba(255,255,0,0.9)', 'rgba(0,255,255,0.9)', 'rgba(255,0,255,0.9)']
  }

  createLayer(map) {
    var self = this
    this.interaction.on('addfeatures', function(event) {
      self.gpx = event.features
      var vectorS = new VectorSource({
        features: event.features
      });
      var gpx_stroke = new Stroke({
        color: self.colors[self.num_drop],
        width: 3
      });

      var gpx_style = new Style({
        stroke: gpx_stroke,
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({color: self.colors[self.num_drop]}),
          stroke: gpx_stroke
        })
      })
      self.num_drop++;
      var l = new VectorLayer({
        style: gpx_style,
        source: vectorS
      });
      l.setZIndex(9998);
      map.addLayer(l);
      map.getView().fit(vectorS.getExtent());
    });

  }
}


export var InfoControl = /*@__PURE__*/(function (Control) {
  function InfoControl(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.innerHTML = '<span class="glyphicon glyphicon-info-sign"></span>';

    var element = document.createElement('div');
    element.style.display = "block";
    element.className = 'info-control ol-unselectable ol-control';
    element.appendChild(button);
    element.setAttribute("id", "info_div");

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.showDialog.bind(this), false);
  }

  if ( Control ) InfoControl.__proto__ = Control;
  InfoControl.prototype = Object.create( Control && Control.prototype );
  InfoControl.prototype.constructor = InfoControl;

  InfoControl.prototype.showDialog = function showDialog () {
    const dialog = document.getElementById('infoDialog');
    document.getElementById("dialog-ok-btn").disabled = true
    dialog.showModal();
    setTimeout(function(){document.getElementById("dialog-ok-btn").disabled = false})
  };

  return InfoControl;
}(Control));