import {transform, fromLonLat} from 'ol/proj';
import Group from 'ol/layer/Group'
import Collection from "ol/Collection";

import {map, all_layers, layer_visible, updatePermalink} from "./state.js"
import {handleVisibility, prerender_func} from "./see_through.js"

export var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

var setBlendMode = function(evt) {
  evt.context.save()
  evt.context.globalCompositeOperation = "multiply";
};

var resetBlendMode = function(evt) {
  evt.context.restore()
};

export var bindLayerListeners = function(layer) {
  layer.on('prerender', setBlendMode);
  layer.on('postrender', resetBlendMode);
};

export var unbindLayerListeners = function(layer) {
  layer.un('prerender', setBlendMode);
  layer.un('postrender', resetBlendMode);
};

export var addLayerToGroup = function(group_name, insert_layer) {
  map.getLayers().forEach(function(layer) {
    if (layer instanceof Group) {
        // look if the group layer already exists
        if(layer.get("title") === group_name){
            // check if layer.getLayers exists
            if (layer.getLayers()){
                // get inner layers from group layer as Collection
                var innerLayers = layer.getLayers(); // no array!
                // new layer to Collection
                innerLayers.push(insert_layer); 
                if (innerLayers instanceof Collection){
                  // set the layer collection of the grouplayer
                  layer.setLayers(innerLayers);
                }
            }
        }
    }
  });

  insert_layer.on('change:visible', updatePermalink);

  if (group_name != 'Features' || insert_layer.get("name") == "naturbasen" || insert_layer.get("name") == "udinaturen") {
    all_layers.push(insert_layer);
  }

};

export var formatLength = function (length) {
  var output;
  if (length > 1000) {
    output = parseFloat(length / 1000).toFixed(3) + ' ' + 'km';
  } else {
    output = parseFloat(length).toFixed(2) + ' ' + 'm';
  }
  return output;
};

export var getJSON = function(json_url,callback) {
  fetch(json_url).then(res => res.json()).then(json => {
    callback(json)
});
}

export var addServiceWorker = function() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js')
    .then(function(registration) {
    })
    .catch(function(error) {
      console.log('Service worker registration failed, error:', error);
    });
  }
}

export var logServer = function(msg)
{
  var json_payload= {
     msg: msg
  }

  var url = "/scripts/helper.py"
  fetch(url, {method: "POST", headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',

       }, body: JSON.stringify(json_payload)});
}