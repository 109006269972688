import {showInfo, routes} from "./routing.js"
import {utils} from "./geometry.js"

export var map;
var view;

export var setMap = function(m) {
  map = m
  view = map.getView()
}

var is_tracking = false

export var isTracking = function()
{
  return is_tracking
}

export var setTracking = function(track)
{
  is_tracking = track
}

export var all_layers = [];
export var layer_visible = {};

export var top_opacity = 1.0;

export var setTopOpacity = function(opa) {
  top_opacity = opa
}

var shouldUpdate = true;

export var isInAllLayers = function(layer) {
  for (var i=0; i<all_layers.length; i++){
    if (all_layers[i] === layer) {
      return true;
    }
  }
  return false;
}

var slider = document.getElementById("myRange");

if (slider) {
  slider.oninput = function() {
    setTopOpacity(this.value / 100);
    updatePermalink();
    map.render();
  }
}

export var zoom = 7;
export var center = [995767.76, 7464291.2];

export var parseLocation = function(location_string) {
  var parts = location_string.split('/');
  if (parts.length === 3) {
    zoom = parseFloat(parts[0], 10);
    center = utils.to3857([
      parseFloat(parts[1]),
      parseFloat(parts[2])
    ]);
  }
}

var parseOpacity = function(layer_string) {
  setTopOpacity(parseFloat(layer_string))
  slider.value = top_opacity * 100;
}

export var naturbasen_init_id = "";
var parseLayers = function(layer_string) {
  var parts = layer_string.split('/');
  parts.forEach(function(l) {
    if (l.startsWith("naturbasen")) {
      naturbasen_init_id = l.substring(10)
      layer_visible["naturbasen"] = true
    } else {
      layer_visible[l] = true;
    }
  });
}

export var parseHash = function(hash) {
  // Remove the first character (i.e. the prepended "#").
  hash = hash.substring(1, hash.length);

  // This is where we will store our properties and values.
  var hashObj = {};

  // Split on the delimiter "&" and for each key/val pair...
  hash.split('&').forEach(function(q) {
    var key_val = q.split('=');
    var key = key_val[0];
    var value = key_val[1];
    if (key == "map") {
      parseLocation(value);
    } else if (key == "layers") {
      parseLayers(value);
    } else if (key == "opacity") {
      parseOpacity(value)
    }
  });

  return hashObj;
}

parseHash(window.location.hash);

// restore the view state when navigating through the history, see
// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
window.addEventListener('popstate', function(event) {
  if (event.state === null) {
    return;
  }
  map.getView().setCenter(event.state.center);
  map.getView().setZoom(event.state.zoom);
  shouldUpdate = false;
});

export var updatePermalink = function() {
  if (!shouldUpdate) {
    // do not update the URL when the view was changed in the 'popstate' handler
    shouldUpdate = true;
    return;
  }

  var info = document.getElementById('info');
  if (info) {
    showInfo();
  }

  var center = utils.to4326(view.getCenter());
  var hash = '#map=' +
      view.getZoom() + '/' +
      Math.round(center[0] * 1000000) / 1000000 + '/' +
      Math.round(center[1] * 1000000) / 1000000;

  var any_visible = false;
  var layer_string = "";
  for (var i=0; i<all_layers.length; i++){
    if (all_layers[i].getVisible()) {
      any_visible = true;
      var layer_name = all_layers[i].get("name")
      if (layer_name == "naturbasen") {
        layer_name += all_layers[i].get("bird_id")
      }
      layer_string += layer_name + "/";
    }
  }
  if (any_visible) {
    hash += "&layers=" + layer_string.substring(0, layer_string.length - 1);
  }
  if (top_opacity != 1) {
    hash += "&opacity=" + top_opacity;
  }

  if (!routes.isEmpty()) {
    hash += routes.listToUrl();
  }
  
  var state = {
    zoom: view.getZoom(),
    center: view.getCenter()
  };
  window.history.pushState(state, 'map', hash);
};