import {getRenderPixel} from 'ol/render';
import Swipe from "ol-ext/control/Swipe";
import {Control} from 'ol/control';

import {map, isInAllLayers, all_layers, top_opacity} from "./state.js";

const E_KEY = 69;
const MINUS_KEY = 189;
const PLUS_KEY = 187;
const UP_ARROW = 38;
const DOWN_ARROW = 40;

export class SeeThrough {
  // constructor 
  constructor(ignore_layer)
  {
    // Control
    this.swipe_ctrl = new Swipe();
    this.ignore_layer = ignore_layer
    if (ignore_layer != null) {
      this.swipe_ctrl.addLayer(ignore_layer)
    }
    this.mode = 0;
    this.radius = 200;
    this.mousePosition = null;
    this.num_layers = 1
    var self = this

    var ModeControl = /*@__PURE__*/(function (Control) {
      function ModeControl(opt_options) {
        var options = opt_options || {};

        var button = document.createElement('button');
        button.innerHTML = '<span class="glyphicon glyphicon-record"></span>';

        var element = document.createElement('div');
        element.style.display = "block";
        element.className = 'mode-select ol-unselectable ol-control';
        element.appendChild(button);
        element.setAttribute("id", "mode_div");

        Control.call(this, {
          element: element,
          target: options.target
        });

        button.addEventListener('click', this.handleUpload.bind(this), false);
      }

      if ( Control ) ModeControl.__proto__ = Control;
      ModeControl.prototype = Object.create( Control && Control.prototype );
      ModeControl.prototype.constructor = ModeControl;

      ModeControl.prototype.handleUpload = function handleUpload () {
        console.log("Mode control clicked");
        self.cycleMode()
        this.getMap().render();
      };

      return ModeControl;
    }(Control));

    this.control = new ModeControl()
  }

  addLayer(layer) {
    var self = this
    layer.on('change:visible', this.handleVisibility.bind(this));
    // before rendering the layer, do some clipping
    layer.on('prerender', function(event){
      if (this.get('name') == "naturbasen") {
        return;
      }
      var ctx = event.context;
      ctx.save();

      if (this.getZIndex() < self.getHighestZ(self.num_layers)) {
        this.setOpacity(1.0);
        return;
      }

      if (isInAllLayers(this)) {
        this.setOpacity(top_opacity);
      }

      if (self.mode == 0) {
        return;
      } else if (self.mode == 1) {
        
        if (self.mousePosition) {
          ctx.beginPath();
          // only show a circle around the mouse
          var pixel = getRenderPixel(event, self.mousePosition);

          var offset = getRenderPixel(event, [self.mousePosition[0] + self.radius, self.mousePosition[1]]);
          var canvasRadius = Math.sqrt(Math.pow(offset[0] - pixel[0], 2) + Math.pow(offset[1] - pixel[1], 2));
          ctx.arc(pixel[0], pixel[1], canvasRadius, 0, 2 * Math.PI);
          ctx.lineWidth = 5 * canvasRadius / self.radius;
          ctx.strokeStyle = 'rgba(0,0,0,0.5)';
          ctx.stroke();
          ctx.clip();
        }
        
      } else if (self.mode == 3) {
        
        if (self.mousePosition) {
          ctx.beginPath();
          // only show a circle around the mouse
          var pixel = getRenderPixel(event, self.mousePosition);

          var offset = getRenderPixel(event, [self.mousePosition[0] + self.radius, self.mousePosition[1]]);
          var canvasRadius = Math.sqrt(Math.pow(offset[0] - pixel[0], 2) + Math.pow(offset[1] - pixel[1], 2));
          ctx.arc(pixel[0], pixel[1], canvasRadius, 0, 2 * Math.PI, true);
          ctx.rect(0, 0, ctx.canvas.width, ctx.canvas.height);
          ctx.lineWidth = 5 * canvasRadius / self.radius;
          ctx.strokeStyle = 'rgba(0,0,0,0.5)';
          ctx.stroke();
          ctx.clip();
        }  
      }
    });

    // after rendering the layer, restore the canvas context
    layer.on('postrender', function(event) {
      if (this.get('name') == "naturbasen") {
        return;
      }

      var ctx = event.context;
      ctx.restore();
    });

    this.handleVisibility();
  }

  createLayer(map, layers) {
    var self = this

    for (var i=0; i<layers.length; i++){
      this.addLayer(layers[i])
    }

    map.getViewport().addEventListener('contextmenu', function (evt) {
      evt.preventDefault();
    });

    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      var curr_key = evt.which;
      if (evt.which === UP_ARROW) {
        self.radius = Math.min(self.radius + 5, 500);
        map.render();
        evt.preventDefault();
      } else if (evt.which === DOWN_ARROW) {
        self.radius = Math.max(self.radius - 5, 25);
        map.render();
        evt.preventDefault();
      }
    });

    var container = document.getElementById('map');

    container.addEventListener('pointermove', function(event) {
      self.mousePosition = map.getEventPixel(event);
      map.render();
    });

    container.addEventListener('pointerout', function() {
      self.mousePosition = null;
      map.render();
    });

    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      if (evt.which == E_KEY) {
        self.cycleMode()
        map.render();
        evt.preventDefault();
      } else if (evt.which == PLUS_KEY) {
        self.num_layers++;
        map.render()
      } else if (evt.which == MINUS_KEY) {
        if (self.num_layers > 0) {
          self.num_layers--
          map.render()
        }
      }
    });

    this.old_mode = 0;
    this.skip = false;
    map.getViewport().addEventListener('mousedown', function(evt){
      // Check if we are clicking on a route feature, and do not show peek hole then
      var feature = map.forEachFeatureAtPixel(map.getEventPixel(evt),
          function (feature, layer) {
              return feature;
          },
          {layerFilter: function(layer) {
            if (layer == self.ignore_layer) {
              return true;
            }
          }});
      if (feature) {
        self.skip = true;
        return;
      }

      if (evt.button == 2) {
        evt.preventDefault();
        self.old_mode = self.mode;
        self.mode = 3;
        map.render();
      }
    }, true);

    map.getViewport().addEventListener('mouseup', function(evt){
      if (self.skip) {
        self.skip = false;
        return;
      }
      if (evt.button == 2) {
        evt.preventDefault();
        self.mode = self.old_mode;
        map.render();
      }
    }, true);

  }

  cycleMode() {
    var tmp_mode = this.mode
    this.mode++
    if (this.mode > 3) {
      this.mode = 0
    }
    if (tmp_mode == 2) {
      map.removeControl(this.swipe_ctrl);
    } else if (this.mode == 2) {
      map.addControl(this.swipe_ctrl);
      this.handleVisibility();
    }
    map.render();
  }

  setMode(m) {
    mode = m
  }

  getHighestZ(n_highest) {
    var z = [0];
    for (var i=0; i<all_layers.length; i++){
      if (all_layers[i].getVisible()) {
        z.push(all_layers[i].getZIndex());
      }
    }
    if (n_highest == 0) {
      return 99998
    }
    if (n_highest > z.length) {
      return 0
    }
    z.sort((a,b)=>a-b)
    z.reverse()
    return z[n_highest - 1];
  }

  handleVisibility() {
    if (this.mode == 2) {
      for (var i=0; i<all_layers.length; i++){
        this.swipe_ctrl.removeLayer(all_layers[i])
        if (all_layers[i].getZIndex() >= this.getHighestZ(this.num_layers)) {
          this.swipe_ctrl.addLayer(all_layers[i])
        }
      }
    }
  }
}