import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {Point} from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Control} from 'ol/control';
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import wizard from  "overpass-wizard"
import OSMXML from 'ol/format/OSMXML';
import {GeoJSON} from 'ol/format';
import {transformExtent} from 'ol/proj';
import {getWidth, getHeight, getCenter} from 'ol/extent';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';
import Projection from 'ol/proj/Projection'
import {asArray} from 'ol/color';

import {all_layers, layer_visible, map, isTracking} from "./state.js"
import {addLayerToGroup, isMobile, bindLayerListeners, unbindLayerListeners, getJSON, logServer} from "./util.js"
import {utils} from "./geometry.js"

proj4.defs('EPSG:25832', "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");

register(proj4)

var myProjection = new Projection({
  code: 'EPSG:25832',
  extent: [120000, 5661139.2, 1378291.2, 6500000]
});

const dataforsyning_attribution = '<a href="https://dataforsyningen.dk/" target="_blank">Dataforsyningen</a>, WMS-tjeneste.'
const waymarked_attribution = '<a href="https://waymarkedtrails.org" target="_blank">Waymarked trails.</a>'
const opentopo_attribution = '<a href="https://opentopomap.org/" target="_blank">Opentopomap.</a>'
const udinaturen_attribution = '<a href="https://udinaturen.dk/" target="_blank">Udinaturen.</a>'

export var createLayerRemote = function(title, service_name, layer_name, zidx, layer_projection, vis, group = 'Denmark') {
  vis = vis || layer_visible[layer_name] == true
  var layer = new TileLayer({
      title: title, // This is the layer title shown in the layer switcher
      preload: Infinity,
      type:'overlay', // use 'base' for base layers, otherwise 'overlay'
      visible: vis, // by default this layer is not visible
      opacity: 1.0, // no transparency
      source: new TileWMS({
          // url: "https://{a-m}.services.kortforsyningen.dk/" + service_name + "?token=1c93f1c81eee600e95e4fc4be486505a",
          // url: "https://services.datafordeler.dk/" + service_name + "/1.0.0/WMS?username=HQFRBQHEVC&password=8Fz5yO3EP*xFQ9B*pI5i",
          url: "https://api.dataforsyningen.dk/" + service_name + "?service=WMS&token=cad28464d8ff412b7a6a50a464713117",
          crossOrigin: "Anonymous",
          attributions: dataforsyning_attribution,
          params:{
              'LAYERS': layer_name,
              'VERSION':'1.3.0',
              'TILED': 'TRUE',
              'TRANSPARENT':'TRUE',
              'FORMAT': "image/png",
              'STYLES':''
          }
      })
    });

  layer.set('name', layer_name)
  layer.setZIndex(zidx);
  addLayerToGroup(group, layer);
  return layer;
}

export var createTrailLayer = function(title, layer_name, zidx, vis) {
  vis = vis || layer_visible[layer_name] == true
  var layer = new TileLayer({
      title: title, // This is the layer title shown in the layer switcher
      preload: Infinity,
      type:'overlay', // use 'base' for base layers, otherwise 'overlay'
      visible: vis, // by default this layer is not visible
      opacity: 1.0, // no transparency
      source: new XYZ({
        url: "https://tile.waymarkedtrails.org/" + layer_name + "/{z}/{x}/{y}.png",
        attributions: waymarked_attribution,
        crossOrigin: "Anonymous"
      }),
    });
  layer.set('name', layer_name)
  layer.setZIndex(zidx);
  addLayerToGroup('Trails', layer);
}

 const udinaturen_telt_fill = new Fill({
   color: 'rgba(255,255,255,0.4)',
 });
 const udinaturen_telt_stroke = new Stroke({
   color: '#3399DD',
   width: 3.0,
 });
 const udinaturen_telt_style = [
   new Style({
     image: new CircleStyle({
       fill: udinaturen_telt_fill,
       stroke: udinaturen_telt_stroke,
       radius: 8,
     }),
     fill: udinaturen_telt_fill,
     stroke: udinaturen_telt_stroke,
   }),
 ];

 const udinaturen_shelter_fill = new Fill({
   color: 'rgba(255,255,255,0.4)',
 });
 const udinaturen_shelter_stroke = new Stroke({
   color: '#1f8c30',
   width: 3.0,
 });
 const udinaturen_shelter_style = [
   new Style({
     image: new CircleStyle({
       fill: udinaturen_shelter_fill,
       stroke: udinaturen_shelter_stroke,
       radius: 8,
     }),
     fill: udinaturen_shelter_fill,
     stroke: udinaturen_shelter_stroke,
   }),
 ];

 const udinaturen_lejr_fill = new Fill({
   color: 'rgba(255,255,255,0.4)',
 });
 const udinaturen_lejr_stroke = new Stroke({
   color: '#BB9933',
   width: 3.0,
 });
 const udinaturen_lejr_style = [
   new Style({
     image: new CircleStyle({
       fill: udinaturen_lejr_fill,
       stroke: udinaturen_lejr_stroke,
       radius: 8,
     }),
     fill: udinaturen_lejr_fill,
     stroke: udinaturen_lejr_stroke,
   }),
 ];

var udinaturen_styles = [udinaturen_telt_style, udinaturen_shelter_style, udinaturen_lejr_style]
export var createGeoJSON = function(title, layer_name, file_name, group, zidx, idx, vis) {
  var defaultStyles = new VectorLayer().getStyleFunction()();
   vis = vis || layer_visible[layer_name] == true;
   var localSource = new VectorSource({
     format: new GeoJSON(),
     attributions: udinaturen_attribution,
     url: "/" + file_name
   });

  var layer = new VectorLayer({
    title: title,
    source: localSource,
    style: udinaturen_styles[idx],
    visible: vis,
  });
  layer.setZIndex(zidx);
  layer.set('name', layer_name)
  addLayerToGroup(group, layer);
}

export var createOpenTopoMapLayer = function(title, layer_name, zidx, vis) {
  vis = vis || layer_visible[layer_name] == true
  var layer = new TileLayer({
      title: title, // This is the layer title shown in the layer switcher
      preload: Infinity,
      type:'overlay', // use 'base' for base layers, otherwise 'overlay'
      visible: vis, // by default this layer is not visible
      opacity: 1.0, // no transparency
      source: new XYZ({
        url: "https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png",
        attributions: opentopo_attribution,
      }),
    });
  layer.set('name', layer_name)
  layer.setZIndex(zidx);
  addLayerToGroup('World', layer);
}

export var createBackgroundOrto = function() {
  return new TileLayer({
    title:'Ortofoto Satellite', // This is the layer title shown in the layer switcher
    preload: Infinity,
    type:'base', // use 'base' for base layers, otherwise 'overlay'
    visible: false, // by default this layer is not visible
    opacity: 1.0, // no transparency
    source: new TileWMS({
        url: "https://api.dataforsyningen.dk/orto_foraar_DAF?service=WMS&token=cad28464d8ff412b7a6a50a464713117",
        attributions: dataforsyning_attribution,
        params:{
            'LAYERS':'orto_foraar',
            'VERSION':'1.3.0',
            'TRANSPARENT':'TRUE',
            'FORMAT': "image/png",
            'STYLES':''
        }
    })
  });
}

export var createBackgroundBing = function() {
  return new TileLayer({
    visible: false,
    title: 'Bing Satellite',
    preload: Infinity,
    type: 'base',
    source: new BingMaps({
      key: 'AuSxyngO3iUBC7ESfIMb6yALs717OgWjvzktDKiWrCHll9YFO7aSkLn44E-7BUdG',
      imagerySet: 'Aerial',
      // use maxZoom 19 to see stretched tiles instead of the BingMaps
      // "no photos at this zoom level" tiles
      maxZoom: 19
    }),
  })
};

export var createBackgroundOsm = function() {
  return new TileLayer({
    title:'OpenStreetMap',
    preload: Infinity,
    type:'base',
    source: new OSM(),
    opacity: 1.0
  });  
}

export class GpsLocation {
  // constructor 
  constructor()
  {
    // Controls
    var self = this
    var GotoPositionControl = /*@__PURE__*/(function (Control) {
      function GotoPositionControl(opt_options) {
        var options = opt_options || {};

        var button = document.createElement('button');
        button.innerHTML = '<span class="glyphicon glyphicon-map-marker"></span>';

        var element = document.createElement('div');
        element.style.display = "none";
        element.className = 'current-position ol-unselectable ol-control';
        element.appendChild(button);
        element.setAttribute("id", "position_div");

        Control.call(this, {
          element: element,
          target: options.target
        });

        button.addEventListener('click', this.handleGotoPosition.bind(this), false);
      }

      if ( Control ) GotoPositionControl.__proto__ = Control;
      GotoPositionControl.prototype = Object.create( Control && Control.prototype );
      GotoPositionControl.prototype.constructor = GotoPositionControl;

      GotoPositionControl.prototype.handleGotoPosition = function handleGotoPosition () {
        this.getMap().getView().setRotation(0);
        this.getMap().getView().setCenter(self.userPosition);
        this.getMap().getView().setZoom(15);
        document.getElementById("position_div").blur();
        document.getElementById("map").focus();
      };

      return GotoPositionControl;
    }(Control));

    this.control = new GotoPositionControl()
  }

  createLayer() {
    var self = this
    this.geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: map.getView().getProjection()
    });
    this.accuracyFeature = new Feature();

    this.geolocation.on('change:accuracyGeometry', function() {
      self.accuracyFeature.setGeometry(self.geolocation.getAccuracyGeometry());
    });

    this.positionFeature = new Feature();
    this.positionFeature.setStyle(new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        })
      })
    }));
    this.geolocation.on('change:position', function() {
      var coordinates = self.geolocation.getPosition();
      
      self.userPosition = coordinates;
      self.positionFeature.setGeometry(coordinates ?
        new Point(coordinates) : null);
    });

    this.geolocation.on('change:tracking', function() {
      var element = document.getElementById("position_div")
      if (element) {
        element.style.display = "block";
      }
    });

    this.geolocation.setTracking(true);

    // handle geolocation error.
    this.geolocation.on('error', function(error) {
      var info = document.getElementById('info');
      info.innerHTML = error.message;
      info.style.display = '';
      document.getElementById("position_div").style.display = "none";
      console.log("Error in gps:" + error.message);
    });

    var geoSource = new VectorSource({
      features: [self.positionFeature]
    })
    this.geoLayer = new VectorLayer({
      // map: map,
      title:'GPS Position',
      visible: true,
      source: geoSource
    });
    if (isMobile) {
      geoSource.addFeature(self.accuracyFeature);
    }

    this.geoLayer.setZIndex(9999);
    addLayerToGroup('Features', this.geoLayer);
  }
}

const O_KEY = 79;
const F_KEY = 70;

export class Overpass {
  // constructor 
  constructor()
  {
    this.overpassQuery = "tourism=camp_site"
    var self = this
    var OverpassControl = /*@__PURE__*/(function (Control) {
      function OverpassControl(opt_options) {
        var options = opt_options || {};

        var button = document.createElement('button');
        button.innerHTML = '<span class="glyphicon glyphicon-search"></span>';

        var element = document.createElement('div');
        element.style.display = "block";
        element.className = 'overpass-search ol-unselectable ol-control';
        element.appendChild(button);
        element.setAttribute("id", "overpass_div");

        Control.call(this, {
          element: element,
          target: options.target
        });

        button.addEventListener('click', this.handleOverpass.bind(this), false);
      }

      if ( Control ) OverpassControl.__proto__ = Control;
      OverpassControl.prototype = Object.create( Control && Control.prototype );
      OverpassControl.prototype.constructor = OverpassControl;

      OverpassControl.prototype.handleOverpass = function handleOverpass () {
        console.log("Overpass control clicked");
        var oq = prompt("Enter overpass search string", self.overpassQuery)
        if (oq == null) {
          return
        }
        self.overpassQuery = oq
        self.loadOverpass();
      };

      return OverpassControl;
    }(Control));

    this.control = new OverpassControl()
  }

  registerTooltip(tooltip)
  {
    this.tooltip = tooltip
  }

  createLayer() {
    var self = this
    this.createOverpassLayer('Overpass', 'overpass', 4001)
    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      var curr_key = evt.which;
      if (evt.which === O_KEY) {
        var oq = prompt("Enter overpass search string", self.overpassQuery)
        if (oq == null) {
          return
        }
        self.overpassQuery = oq
        self.loadOverpass();
        evt.preventDefault();
      } else if (evt.which === F_KEY) {
        self.loadOverpass();
        evt.preventDefault();
      }
    });
  }

  createOverpassLayer(title, layer_name, zidx, vis) {
    var defaultStyles = new VectorLayer().getStyleFunction()();
    defaultStyles[0].stroke_.width_ = 4;

    vis = vis || layer_visible[layer_name] == true;
    this.overpassSource = new VectorSource({
      format: new OSMXML(),
    });

    this.overpassLayer = new VectorLayer({
      title: title,
      visible: vis,
      source: this.overpassSource,
      style: function(feature, resolution) {
        var minSizePx = 30;
        var minSize = minSizePx * resolution;
        var extent = feature.getGeometry().getExtent();

        if (getWidth(extent) < minSize || getHeight(extent) < minSize) {
          // special style for polygons that are too small
          var center = new Point(getCenter(extent));
          return new Style({
            geometry: center,
            image: new CircleStyle({
              radius: 6,
              fill: new Fill({color: 'rgba(255,255,255,0.5)'}),
              stroke: new Stroke({color: '#3399CC', width: 2})
            })
          })
        } else {
          // normal style
          return defaultStyles;
        }
      }
    });
    this.overpassLayer.set('name', layer_name)
    this.overpassLayer.setZIndex(zidx);
    addLayerToGroup('Features', this.overpassLayer);
  }

  handleClick(feature, evt) {
    var type = "way"

    if (feature.get('geometry') instanceof Point) {
      type = "node"
    }
    var id = feature.id_
    var action = function() {
      window.open("https://www.openstreetmap.org/" + type + "/" + id, '_blank')
    }
    if (isMobile) {
      this.tooltip.displayTooltip(evt, action)
    } else {
      action()
    }
  }

  loadOverpass() {
    // if (overpassQuery.startsWith("b:")) {
    //   loadBirds();
    //   return;
    // }
    if (isTracking()) {
      logServer("Searching: " + this.overpassQuery + ", " + window.location.href)
    }
    var extent = map.getView().calculateExtent(map.getSize());
    var projection = map.getView().getProjection();
    var epsg4326Extent = transformExtent(extent, projection, 'EPSG:4326');
    var client = new XMLHttpRequest();
    var start;
    var self = this
    // client.open('POST', 'http://192.168.0.34/api/interpreter');
    client.open('POST', 'https://overpass-api.de/api/interpreter');
    client.addEventListener('load', function () {
      var features = new OSMXML().readFeatures(client.responseText, {
        featureProjection: map.getView().getProjection(),
      });
      var end = window.performance.now();
      var time = (end - start) / 1000;
      if (features.length > 8000) {
        alert("Found too many features (" + features.length + " > 8000) not showing")
        return;
      }
      console.log("Found: " + features.length + " features in " + time + " seconds")
      for (var i = 0; i < features.length; i++) {
        features[i].set('type', 'overpass')
      }
      self.overpassSource.clear();
      self.overpassSource.addFeatures(features);
      self.overpassLayer.setVisible(true);
    });

    var stringExtent = epsg4326Extent[1] + ',' + Math.max(epsg4326Extent[0], -180) + ',' + epsg4326Extent[3] + ',' + Math.min(epsg4326Extent[2], 180);

    var q;

    switch(this.overpassQuery) {
      case "vand":
        q = "amenity=drinking_water or building=church"
        break;
      case "camping":
        q = "tourism=camp_site or shelter_type=lean_to or tourism=alpine_hut"
        break;
      case "toilet":
      case "toiletter":
        q = "amenity=toilets"
        break;
      case "butikker":
        q = "shop=*"
        break;
      case "tog":
        q = "railway=station or public_transport=station"
        break;
      case "bus":
        q = "highway=bus_stop"
        break;
      case "tog+bus":
        q = "railway=station or public_transport=station or public_transport=platform or highway=bus_stop"
        break;
      case "parking":
        q = "amenity=parking"
        break;
      default:
        q = this.overpassQuery
    }

    var query = wizard(q, {outputFormat:"xml", timeout: 60, maxSize: 20971520, outputMode: "recursive", globalBbox:false,comment:false})
    query = query.replaceAll("{{bbox}}", stringExtent)
    console.log("Searching for: " + q)
    start = window.performance.now();
    client.send(query);
  }
}

const T_KEY = 84;

export class ShadowLayer {

  constructor() {
    var self = this

    this.skygge_layer_t = createLayerRemote('Skyggekort Terræn', 'dhm_DAF', 'dhm_terraen_skyggekort', 1000);
    this.skygge_layer_to = createLayerRemote('Skyggekort Overdrevet', 'dhm_historik_DAF', 'hillshade_1_6m', 1001);
    this.skygge_layer_o = createLayerRemote('Skyggekort Overflade', 'dhm_DAF', 'dhm_overflade_skyggekort', 1002);
    bindLayerListeners(this.skygge_layer_t)
    bindLayerListeners(this.skygge_layer_to)
    bindLayerListeners(this.skygge_layer_o)

    this.blend = true
    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      var curr_key = evt.which;
      if (evt.which === T_KEY) {
        self.blend = !self.blend
        if (self.blend) {
          bindLayerListeners(self.skygge_layer_t)
          bindLayerListeners(self.skygge_layer_to)
          bindLayerListeners(self.skygge_layer_o)
        } else {
          unbindLayerListeners(self.skygge_layer_t)
          unbindLayerListeners(self.skygge_layer_to)
          unbindLayerListeners(self.skygge_layer_o)
        }
        map.render();
        evt.preventDefault();
      }
    });
  }
}

var url_valhalla_route = 'https://valhalla.witx.dk/';

export class Isochrone {
  // constructor 
  constructor(map)
  {
    var self = this
    this.isochrone_mode = false;
    map.on('singleclick', function(evt){
      if (self.isochrone_mode) {
        self.isochroneLayer.setVisible(true);
        self.getContours(evt.coordinate);
        return;
      }
    });

    const A_KEY = 65;
    self.key_down = null

    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      var curr_key = evt.which;
      if (evt.which === A_KEY && !self.key_down) {
        self.isochrone_mode = !self.isochrone_mode;
        evt.preventDefault();
        map.render();
      } 
      self.key_down = evt.which
    });

    document.addEventListener('keyup', function(evt) {
      if (evt.which === A_KEY) {
        self.isochrone_mode = false
        evt.preventDefault();
      }
      self.key_down = null
    })

    this.createIsochroneLayer('Isochrones', 'isochrones', 2001)
  }

  parseContour(N, min) {
    try {
      let range = n => [...Array(n).keys()]

      var values = [];
      values = range(N).map(i => (i + 1) * min);

      return values.map(t => Object.fromEntries([["time", t]]));
    } catch (e) {
      console.error(e);
    }
  }

  getContours(coord) {
    //build url
    var url_valhalla_isochrone = 'https://valhalla.witx.dk/isochrone?json=';
    var url = url_valhalla_isochrone;
    var json = {}
    var coord4326 = utils.to4326(coord); 
    json['locations'] = [{"lat":coord4326[1], "lon":coord4326[0]}];
    json['costing'] = 'auto'//document.getElementById('costing').value;
    json['denoise'] = 5.0//document.getElementById('denoise').value;
    json['generalize'] = 20//document.getElementById('generalize').value;
    json['contours'] =  this.parseContour(6, 5);
    json['polygons'] = true;

    url += escape(JSON.stringify(json));
    //grab the url

    var self = this

    getJSON(url,function(isochrones){
      console.log(isochrones)
      var features = new GeoJSON().readFeatures(isochrones, {
        featureProjection: map.getView().getProjection(),
      });
      self.isochroneSource.clear()
      self.isochroneSource.addFeatures(features)
    })
  }

  isochroneStyleFunction(feature) {
    var c =  asArray(feature.get('color'));
    var fillColor = c.slice();
    fillColor[3] = feature.get('opacity');  // change the alpha of the color
    var strokeColor = c.slice();
    strokeColor[3] = feature.get('opacity') * 2
    return [new Style({
      stroke: new Stroke({
        color: strokeColor,
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    })]
  };

  createIsochroneLayer(title, layer_name, zidx, vis) {
     vis = vis || layer_visible[layer_name] == true;
     this.isochroneSource = new VectorSource({
       projection: myProjection,
       format: new GeoJSON()
     });


    this.isochroneLayer = new VectorLayer({
      title: title,
      source: this.isochroneSource,
      visible: vis,
      style: this.isochroneStyleFunction
    });
    this.isochroneLayer.setZIndex(zidx);
    addLayerToGroup('Features', this.isochroneLayer);
  }

}

const X_KEY = 88;
const C_KEY = 67;

export class BackgroundLayers {
  // constructor 
  constructor()
  {
    this.bing_layer = createBackgroundBing()
    this.osm_layer = createBackgroundOsm();
    this.orto_layer = createBackgroundOrto()

    var self = this

    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      var curr_key = evt.which;
      if (evt.which === X_KEY) {
        self.bing_layer.setVisible(false);
        if (self.orto_layer.getVisible()) {
          self.osm_layer.setVisible(true);
          self.orto_layer.setVisible(false);
        } else {
          self.osm_layer.setVisible(false);
          self.orto_layer.setVisible(true);
        }
        map.render();
        evt.preventDefault();
      } else if (evt.which === C_KEY) {
        self.orto_layer.setVisible(false);
        if (self.bing_layer.getVisible()) {
          self.osm_layer.setVisible(true);
          self.bing_layer.setVisible(false);
        } else {
          self.osm_layer.setVisible(false);
          self.bing_layer.setVisible(true);
        }
        map.render();
        evt.preventDefault();
      } 
    });

  }


  getLayers() {
    return [this.osm_layer, this.orto_layer, this.bing_layer]
  }
}

export class BackgroundWorldLayers {
  // constructor 
  constructor()
  {
    this.bing_layer = createBackgroundBing()
    this.osm_layer = createBackgroundOsm();

    var self = this

    document.addEventListener('keydown', function(evt) {
      var gcdtext = document.getElementById('gcd-input-query');
      if (gcdtext === document.activeElement) {
        return;
      }
      var curr_key = evt.which;
      if (evt.which === C_KEY) {
        if (self.bing_layer.getVisible()) {
          self.osm_layer.setVisible(true);
          self.bing_layer.setVisible(false);
        } else {
          self.osm_layer.setVisible(false);
          self.bing_layer.setVisible(true);
        }
        map.render();
        evt.preventDefault();
      } 
    });

  }


  getLayers() {
    return [this.osm_layer, this.bing_layer]
  }
}
