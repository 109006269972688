import 'ol/ol.css';
import "ol-ext/dist/ol-ext.css";
import "ol-geocoder/dist/ol-geocoder.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "./index.css"
import Map from 'ol/Map';
import View from 'ol/View';
import ScaleLine from 'ol/control/ScaleLine'
import LayerSwitcher from 'ol-layerswitcher/dist/ol-layerswitcher'
import Group from 'ol/layer/Group'
import {defaults as defaultInteractions, PinchZoom} from 'ol/interaction';
import {defaults as defaultControls, FullScreen} from 'ol/control';
import WebGLPointsLayer from 'ol/layer/WebGLPoints.js';
import {GeoJSON} from 'ol/format';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature';
import {Point} from 'ol/geom';
import VectorSource from 'ol/source/Vector';

import {DoubleTapZoom, 
        MouseWheelLayer} from "../controls.js"
import {setMap, map, updatePermalink,
        zoom, center, parseLocation, parseHash} from "../state.js"
import {
        BackgroundWorldLayers} from "../free_layers.js"
import {addLayerToGroup, isMobile, addServiceWorker, logServer} from "../util.js"
import {utils} from "../geometry.js"

var double_tap = new DoubleTapZoom();
var mouse_layer = new MouseWheelLayer();

var background_layers = new BackgroundWorldLayers()

var base_group = new Group({
  fold: 'open',
  'title': 'Base maps',
  layers: background_layers.getLayers()});
var feature_group = new Group({
  fold: 'close',
  'title': 'Features',
  });

var layer_switcher = new LayerSwitcher({activationMode:'mouseover',groupSelectStyle: 'none'});

parseLocation("5/-91.541494/38.925792")
parseHash(window.location.hash);

var tmp_map = new Map({
  interactions: defaultInteractions( { altShiftDragRotate:false, pinchRotate:false } ).extend([mouse_layer, new PinchZoom()]),
  controls: defaultControls().extend([
    new FullScreen(),
    layer_switcher
  ]),
  layers: [
      feature_group,
      base_group
      ],
  moveTolerance: (isMobile ? 3 : 1),
  target: document.getElementById('map'),
  view: new View({
      center: center, // start center position
      zoom: zoom, // start zoom level
      projection: 'EPSG:3857', // use our custom projection defined earlier
      constrainResolution: true
  })
});

setMap(tmp_map)

map.on('moveend', updatePermalink);

// addKeyboardControls(map)

if (isMobile) {
  map.addInteraction(double_tap)
}

map.on('singleclick', function(evt){
  if (double_tap.isActive()) {
    return
  }
  let f = map.forEachFeatureAtPixel(
    evt.pixel,
    function(ft, layer) {
        return ft;
    }
  );
});

// createGeoJSON("Birds", "birds", "small.geojson", "Features", 2003)

const birdmap = {};

const oldColor = 'rgba(242,56,22,0.61)';
const newColor = '#42c2f5';

const style = {
  variables: {
    minDay: 337,
    maxDay: 341,
    allDay: -1,
    bird: "california quail",
  },
  // filter: ['==', ['get', 'timestamp'], ['var', 'minDay']],
  filter: ['any', 
           ['==', ['get', 'timestamp'], ['var', 'minDay']],
           ['==', ['var', 'minDay'], ['var', 'allDay']],
          ],
  symbol: {
    symbolType: 'circle',
    size: 10,
    color: newColor,
    opacity: 0.7,
  },
};

const birdbuddy_attribution = '<a href="https://live.mybirdbuddy.com/" target="_blank">BirdBuddy</a>'
var vectorSource = new VectorSource({
  attributions: birdbuddy_attribution,
});

var selectBird = function(bird) {
  vectorSource.clear()
  
  logServer("Bird selected: " + bird + ", " + window.location.href)

  var features = []
  if (bird == "all") {
    for (var key in birdmap){
      var entry = birdmap[key];
      for (let i = 0; i < entry.length; i++) {
        const element = entry[i];      
        features.push(
          new Feature({
            timestamp: element.timestamp,
            geometry: new Point(element.coords),
          })
        )
      }
    }
  } else {
    var entry = birdmap[bird];
    for (let i = 0; i < entry.length; i++) {
      const element = entry[i];      
      features.push(
        new Feature({
          timestamp: element.timestamp,
          geometry: new Point(element.coords),
        })
      )
    }
  }
  vectorSource.addFeatures(features)
}

function dateFromDay(year, day){
  var date = new Date(year, 0); // initialize a date in `year-01-01`
  return new Date(date.setDate(day)); // add the number of days
}

var showDate = function(day) {
  var info = document.getElementById('info-pre');
  info.innerText = dateFromDay(2022, day).toDateString();
  info.style.display = '';
  info.style.opacity = 1;
}

// handle input values & events
const dateRange = document.getElementById('dateRange');

dateRange.addEventListener('input', function () {
  var day = 297 + parseInt(dateRange.value)
  showDate(day)
  style.variables.minDay = day;
  map.render();
});

//Create and append select list
var selectList = document.createElement("select");
selectList.classList.add('bird-selecter');
selectList.id = "birdSelect";
document.body.appendChild(selectList);

var loadingDiv = document.getElementById('loading');
var loadingBarDiv = document.getElementById('myProgress');
var loadingBar = document.getElementById('myBar');

function showSpinner() {
  loadingDiv.style.visibility = 'visible';
  loadingBarDiv.style.visibility = 'visible';
}

function hideSpinner() {
  loadingDiv.style.visibility = 'hidden';
  loadingBarDiv.style.visibility = 'hidden';
}

function updateProgress (prog) {
  // console.log("Progress: " + prog.toFixed(1) + " %")
  loadingBar.style.width = prog + "%";
  loadingBar.innerHTML = prog.toFixed(1) + "%";
}

logServer("Opened: " + window.location.href)

const start = Date.now();

// load data;
const client = new XMLHttpRequest();
client.open('GET', '/birds.csv');
client.onloadstart = function () {
  showSpinner();
}
client.onprogress = function (event) {
  var prog = (75*event.loaded/event.total)
  updateProgress(prog)
  // console.log("Got progress: " + prog.toFixed(1) + " %")
}
client.onload = function () {
  const csv = client.responseText;

  let prevIndex = csv.indexOf('\n') + 1; // scan past the header line
  var total_lines = (csv.match(/\n/g)||[]).length;
  let curIndex;
  var count = 0
  var last_prog = 0
  var loadData = function() {
    while((curIndex = csv.indexOf('\n', prevIndex)) != -1) {
      const line = csv.substr(prevIndex, curIndex - prevIndex).split(',');
      prevIndex = curIndex + 1;

      const coords = fromLonLat([parseFloat(line[1]), parseFloat(line[0])]);
      if (isNaN(coords[0]) || isNaN(coords[1])) {
        // guard against bad data
        continue
      }
      var bird_name = line[3].trim()
      if (!(bird_name in birdmap)) {
        birdmap[bird_name] = []
      }
      birdmap[bird_name].push(
        {
          coords: coords,
          timestamp: parseInt(line[2]) || 0
        }
      );
      var prog = 75 + Math.round(count / total_lines * 25)
      if (prog != last_prog) {
        updateProgress(prog, true)
        last_prog = prog
        window.requestAnimationFrame(loadData)
        return
      }
      count++
    }
    hideSpinner();

    var def_bird = "house finch"
    selectBird(def_bird)
    //Create array of options to be added
    const keys = Object.keys(birdmap);
    keys.sort()
    var show_all = false;
    //Create and append the options
    var def_idx = 0;
    if  (show_all) {
      var option = new Option("All", "all")
      selectList.appendChild(option);
    }
    for (var i = 0; i < keys.length; i++) {
      var option = new Option(keys[i][0].toUpperCase() + keys[i].substring(1), keys[i])
      selectList.appendChild(option);
      if (keys[i] == def_bird) {
        if (show_all) {
          def_idx = i + 1
        } else {
          def_idx = i
        }
      }
    }
    selectList.selectedIndex = def_idx
    const end = Date.now();
    var time_info = "Loading time: " + (end - start) / 1000.0 + " s"
    logServer(time_info)
  }
  window.requestAnimationFrame(loadData)
  // alert("Birds loaded!")
};
// showSpinner()
client.send();
// hideSpinner();

var day = 327
showDate(day)
style.variables.minDay = day;

var bird_layer = new WebGLPointsLayer({
  title: "Birds",
  style: style,
  source: vectorSource,
  // disableHitDetection: true,
})

bird_layer.setZIndex(2000);
bird_layer.set('name', "Birds")

addLayerToGroup("Features", bird_layer)

selectList.onchange = function () {
  selectBird(selectList.value)
}

const allCheck = document.getElementById('allCheck')
const playBtn = document.getElementById('playBtn')

var playing = false
var timer = null
var setPlay = function(play) {
  logServer("Playing: " + play + ", " + window.location.href)
  if (play) {
    playing = true
    timer = setInterval(function(){
      dateRange.value++;
      dateRange.dispatchEvent(new Event('input'));
    }, 500);
    playBtn.innerHTML = '<span class="glyphicon glyphicon-pause"></span> Pause'
  } else {
    playing = false
    clearInterval(timer);
    playBtn.innerHTML = '<span class="glyphicon glyphicon-play"></span> Play'
  }
  map.render();
}

allCheck.addEventListener('change', (event) => {
  logServer("Checked: " + event.currentTarget.checked + ", " + window.location.href)
  if (event.currentTarget.checked) {
    style.filter = null;
    var info = document.getElementById('info-pre');
    info.innerText = "All";
    info.style.display = '';
    info.style.opacity = 1;
    style.variables.minDay = -1;
    dateRange.style.visibility = 'hidden';
    playBtn.style.visibility = 'hidden';
    setPlay(false)
  } else {
    style.filter = ['==', ['get', 'timestamp'], ['var', 'minDay']];
    var day = 297 + parseInt(dateRange.value)
    showDate(day)
    style.variables.minDay = day;
    dateRange.style.visibility = 'visible';
    playBtn.style.visibility = 'visible';
  }
  map.render();
})

playBtn.onclick = function() {
  setPlay(!playing)
}

// myVar = setTimeout(function, milliseconds);
// clearTimeout(myVar);

var requestUrl = "https://ipinfo.io/json?token=4fea2d2bfee3c0";

fetch(requestUrl).then(function(r) {
  return r.json();
}).then(function(json) {
  var info = "Info: " + json.country + ", " + json.city + ", (" + json.loc + ")"
  // console.log(info)
  logServer(info)
});