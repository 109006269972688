import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {Circle as CircleStyle, Fill,Style, Icon, Stroke} from 'ol/style';
import Collection from "ol/Collection";
import { Modify } from "ol/interaction";
import Feature from 'ol/Feature';
import {getLength} from 'ol/sphere';
import {LineString, Point} from 'ol/geom';
import {transform, fromLonLat} from 'ol/proj';
import {GPX, GeoJSON, Polyline} from 'ol/format';
import {Control} from 'ol/control';
import MultiLineString from 'ol/geom/MultiLineString';
import Profile from "ol-ext/control/Profile";
import Hover from "ol-ext/interaction/Hover";
import simplify from '@turf/simplify';

import {formatLength, isMobile, getJSON, logServer} from "./util.js"
import {map, updatePermalink, isTracking} from "./state.js"
import {utils} from "./geometry.js"

// our simple throttle function
function throttle(callback, limit) {
    var wait = false;                  // Initially, we're not waiting
    return function () {               // We return a throttled function
        if (!wait) {                   // If we're not waiting
            // callback.call();           // Execute users function
            wait = true;               // Prevent future invocations
            setTimeout(function () {   // After a period of time
                callback.call();
                wait = false;          // And allow future invocations
            }, limit);
        }
    }
}

export class HistoryPoint {

  constructor(coords, straight)
  {
    this.coords = coords;
    this.straight = straight;
  }
}

export class RoutePoint {
  // constructor 
  constructor(point, route)
  {
    this.point = point;
    this.route = route;
    this.point_feature = null;
    this.seq = 0
    this.straight = false
  }
}

// User defined class node 
export class Node { 
  // constructor 
  constructor(element) 
  { 
    this.element = element; 
    this.next = null;
    this.prev = null;
  } 
} 

// linkedlist class 
export class LinkedList { 
  constructor() 
  { 
    this.head = null; 
    this.size = 0; 
  }

  // adds an element at the end 
  // of list 
  add(element) 
  { 
    // creates a new node 
    var node = new Node(element); 

    // to store current node 
    var current; 

    // if list is Empty add the 
    // element and make it head 
    if (this.head == null) 
      this.head = node; 
    else { 
      current = this.head; 

      // iterate to the end of the 
      // list 
      while (current.next) { 
        current = current.next; 
      } 

      // add node 
      current.next = node;
      node.prev = current;
    } 
    this.size++; 
  } 

  // insert element at the position index 
  // of the list 
  insertAt(element, index) 
  { 
    if (index > 0 && index > this.size) 
      return false; 
    else { 
      // creates a new node 
      var node = new Node(element); 
      var curr, prev; 

      curr = this.head; 

      // add the element to the 
      // first index 
      if (index == 0) { 
        node.next = this.head;
        this.head.prev = node;
        this.head = node; 
      } else { 
        curr = this.head; 
        var it = 0; 

        // iterate over the list to find 
        // the position to insert 
        while (it < index) { 
          it++; 
          prev = curr; 
          curr = curr.next; 
        } 

        // adding an element 
        node.next = curr;
        curr.prev = node;
        node.prev = prev;
        prev.next = node; 
      } 
      this.size++; 
    } 
  } 

  // removes an element from the 
  // specified location 
  removeFrom(index) 
  { 
    if (index > 0 && index > this.size) 
      return -1; 
    else { 
      var curr, prev, it = 0; 
      curr = this.head; 
      prev = curr; 

      // deleting first element 
      if (index === 0) { 
        this.head = curr.next;
        curr.prev = null;
      } else { 
        // iterate over the list to the 
        // position to removce an element 
        while (it < index) { 
          it++; 
          prev = curr; 
          curr = curr.next; 
        } 

        // remove the element 
        prev.next = curr.next;
        curr.next.prev = prev;
      } 
      this.size--; 

      // return the remove element 
      return curr.element; 
    } 
  }

  changeAt(element, index) 
  { 
    if (index > 0 && index > this.size) {
      return false; 
    } else { 
      var curr, prev, it = 0; 
      curr = this.head; 
      prev = curr; 

      // deleting first element 
      if (index === 0) { 
        this.head.element = element
      } else { 
        // iterate over the list to the 
        // position to removce an element 
        while (it < index) { 
          it++; 
          prev = curr; 
          curr = curr.next; 
        } 

        // remove the element 
        curr.element = element;
      } 

      // return the remove element 
      return true; 
    } 
  }

  getAt(index) 
  { 
    if (index > 0 && index > this.size) {
      return null; 
    } else { 
      var curr, prev, it = 0; 
      curr = this.head; 
      prev = curr; 

      // deleting first element 
      if (index === 0) { 
        return this.head.element;
      } else { 
        // iterate over the list to the 
        // position to removce an element 
        while (it < index) { 
          it++; 
          prev = curr; 
          curr = curr.next; 
        } 
      } 

      // return the remove element 
      return curr.element; 
    } 
  }

  // removes a given element from the 
  // list 
  removeElement(element) 
  { 
    var current = this.head; 
    var prev = null; 

    // iterate over the list 
    while (current != null) { 
      // comparing element with current 
      // element if found then remove the 
      // and return true 
      if (current.element === element) { 
        if (prev == null) { 
          this.head = current.next;
          current.prev = null;
        } else { 
          prev.next = current.next;
        }
        if (current.next) {
          current.next.prev = prev;
        }
        this.size--; 
        return current.element; 
      } 
      prev = current; 
      current = current.next; 
    } 
    return -1; 
  } 

  getPrev(element) 
  { 
    var current = this.head; 

    // iterae over the list 
    while (current != null) { 
      // compare each element of the list 
      // with given element 
      if (current.element === element) {
        if (current.prev) {
          return current.prev.element; 
        } else {
          return null;
        }
      }
      current = current.next; 
    } 

    // not found 
    return null; 
  }

  getNext(element) 
  { 
    var current = this.head; 

    // iterae over the list 
    while (current != null) { 
      // compare each element of the list 
      // with given element 
      if (current.element === element) {
        if (current.next) {
          return current.next.element; 
        } else {
          return null;
        }
      }
      current = current.next; 
    } 

    // not found 
    return null; 
  }

  // finds the index of element 
  indexOf(element) 
  { 
    var count = 0; 
    var current = this.head; 

    // iterae over the list 
    while (current != null) { 
      // compare each element of the list 
      // with given element 
      if (current.element === element) 
        return count; 
      count++; 
      current = current.next; 
    } 

    // not found 
    return -1; 
  } 

  isFirstElement(element)
  {
    if (!this.head) {
      return false
    }
    return element === this.head.element;
  }

  isLastElement(element)
  {
    var node = this.getLastElement();
    if (!node) {
      return false
    }
    return element === node.element;
  }

  getFirstElement()
  {
    return this.head;
  }

  getLastElement()
  {
    if (this.isEmpty()) {
      return null;
    }
    var curr = this.head; 
    while (curr.next) { 
      curr = curr.next; 
    }
    return curr.element;
  }

  // checks the list for empty 
  isEmpty() 
  { 
    return this.size === 0; 
  } 

  // gives the size of the list 
  length() 
  { 
    return this.size; 
  }

  clear()
  {
    this.size = 0;
    this.head = null;
  }

  // prints the list items 
  printList() 
  { 
    var curr = this.head; 
    var str = ""; 
    while (curr) { 
      str += curr.element + " "; 
      curr = curr.next; 
    } 
    console.log(str); 
  }

  getRouteFeatures()
  {
    var curr = this.head;
    var features = [];
    while (curr) {
      if (curr.element.route) {
        features.push(curr.element.route);
      }
      curr = curr.next;
    }
    return features
  }

  listToUrl() 
  { 
    var str = "&route="; 
    var curr = this.head; 
    while (curr) { 
      str += curr.element.point.map(number=>number.toFixed(5))
      if (curr.next) {
        str += ","
      }
      curr = curr.next; 
    }

    var curr = this.head;
    var count = 0
    var straight = false
    while (curr) {
      if (curr.element.straight) {
        if (!straight) {
          str += "&straight="
          straight = true
        } else {
          str += ","
        }
        str += count
      }
      count++
      curr = curr.next;
    }

    return str;
  } 

  getDistance() 
  { 
    var curr = this.head; 
    var l = 0; 
    while (curr) {
      if (curr.element.route) {
        l += getLength(curr.element.route.get('geometry'))
      }
      curr = curr.next; 
    } 
    return l;
  }

  getArray()
  {
    var curr = this.head;
    var list = [];
    while (curr) {
      list.push(new HistoryPoint(utils.to3857(curr.element.point), curr.element.straight))
      curr = curr.next;
    }
    return list;
  }
}

const PREV = 0
const NEXT = 1
const BOTH = 2

var redrawRoute = function(node, mode, update_link = true) {
    var new_point = node.point.join();
    var prev = routes.getPrev(node);
    if ((mode == PREV || mode == BOTH) && prev) {
      var old_point = prev.point.join();
      makeRoute(old_point, new_point, prev);
    }
    var next = routes.getNext(node);
    if ((mode == NEXT || mode == BOTH) && next) {
      var next_point = next.point.join();
      makeRoute(new_point, next_point, node);
    }
    if (update_link) {
      updatePermalink();
    } else {
      showInfo()
    }
  },
  redrawAll = function() {
    var node = routes.getFirstElement().element;
    redrawRoute(node, NEXT, false)
    while(node != routes.getLastElement()) {
      node = routes.getNext(node);
      redrawRoute(node, NEXT, false)
    }
    updatePermalink()
  },
  createFeature = function(coord, node, insert) {
    var feature = new Feature({
      type: 'place',
      geometry: new Point(fromLonLat(coord))
    });
    if (!routes.getPrev(node)) {
      feature.setStyle(styles.icon_start);
    } else if (!routes.getNext(node)) {
      feature.setStyle(styles.icon_end);
    } else {
      feature.setStyle(styles.icon_mid);
    }
    feature.set('node', node)
    node.point_feature = feature;
    routeSource.addFeature(feature);
    var cb = function(){
      if (node.ignore_cb) {
        node.ignore_cb = false
        return
      }
      var coord = feature.getGeometry().getCoordinates()
      node.point = utils.to4326(coord);
      redrawRoute(node, BOTH, false);

      routePoint.setGeometry(new Point(node.point));

      var p = profile.showAt(coord);
      if (!p) {
        return
      }
      profile.popup(p[2]+" m");
      routePoint.setStyle([]);
    }
    feature.on('change',throttle(cb, 50),feature);
    route_collection.push(feature)
  },
  createRoute = function(polyline, parent, precision) {
    // route is LineString
    var route = new Polyline({
      factor: precision
    }).readGeometry(polyline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });
    var feature = new Feature({
      type: 'route',
      geometry: route
    });
    feature.set('parent', parent)
    feature.setStyle(styles.route);
    if (!parent.route) {
      parent.route = feature;
    } else {
      routeSource.removeFeature(parent.route)
      parent.route = feature;
    }
    routeSource.addFeature(feature);
    showInfo()
  },
  createRoute2 = function(route, parent, precision) {
    // route is LineString
    var feature = new Feature({
      type: 'route',
      geometry: route
    });
    feature.set('parent', parent)
    feature.setStyle(styles.route);
    if (!parent.route) {
      parent.route = feature;
    } else {
      routeSource.removeFeature(parent.route)
      parent.route = feature;
    }
    routeSource.addFeature(feature);
    profile.setGeometry(GetPolyLineFromFeatures(), {graduation: 10});
    profile.full_show()
    showInfo()
  };

var planner;
var makeRouteOSRM;
var makeRouteValhalla;
var refreshRoute;

var undo_stack = [];
var redo_stack = [];

// For sequnce control
var route_sequence = 0

var makeRouteStraight = function(A, B, parent) {
  A = A.split(",");
  B = B.split(",");
  
  var route = new LineString([transform(A, 'EPSG:4326', 'EPSG:3857'), transform(B, 'EPSG:4326', 'EPSG:3857')], 'XY');

  var format = new Polyline({factor: 1e5});

  var poly_string = format.writeGeometry(route, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857'
  });

  route_sequence++
  getElevation(poly_string, parent, route_sequence, 1e5, true)
  showInfo()
}

makeRouteOSRM = function(A, B, parent) {
  route_sequence++
  var local_seq = route_sequence
  fetch(url_osrm_route + A + ';' + B + '?overview=full', {method:'GET', headers: {'Authorization': 'Basic ' + btoa('osrm:i_want_routes')}}).then(function(r) {
    return r.json();
  }).then(function(json) {
    if(json.code !== 'Ok') {
      console.log('No route found.');
      return;
    }

    if (local_seq < parent.seq ) {
      return
    }
    // createRoute(json.routes[0].geometry, parent, 1e5);
    getElevation(json.routes[0].geometry, parent, local_seq)
  });
}

var url_valhalla_route = 'https://valhalla.witx.dk/';
// var url_valhalla_route = "http://192.168.0.27:8002/"

makeRouteValhalla = function(A, B, parent) {
  A = A.split(",");
  B = B.split(",");
  var local_seq = route_sequence
  var json_payload= {
     "locations":[
        {
           "options":{
              "allowUTurn":false
           },
           "latLng":{
              "lat": A[1],
              "lng": A[0]
           },
           "_initHooksCalled":true,
           "lat": A[1],
           "lon": A[0]
        },
        {
           "options":{
              "allowUTurn":false
           },
           "latLng":{
              "lat": B[1],
              "lng": B[0]
           },
           "_initHooksCalled":true,
           "lat": B[1],
           "lon": B[0]
        }
     ],
     "directions_type": "none",
     "costing":"pedestrian",
     "costing_options":{
      "bicycle":{
        "bicycle_type":"Cross",
        // "cycling_speed":17,
        "use_roads": 1.0,
        "use_hills": 1.0,
        "use_ferry": 0.0,
        "avoid_bad_surfaces": 0.0
      },
      "pedestrian":{
        // "cycling_speed":17,
        "walkway_factor": 0.0,
        "alley_factor": 0.0,
        "use_ferry": 0.0,
        "step_penalty": 0.0,
        "driveway_factor": 0.0,
        "max_hiking_difficulty": 6
      }
    }
  }

  var url = url_valhalla_route + "route?json=" + JSON.stringify(json_payload);
  fetch(url, {method:'GET', headers: {'Authorization': 'Basic ' + btoa('geoserver:iwantmaps')}}).then(function(r) {
    return r.json();
  }).then(function(json) {
    // if(json.code !== 'Ok') {
    //   console.log('No route found: ' + json.code);
    //   return;
    // }
    if (local_seq < parent.seq ) {
      return
    }
    // createRoute(json.trip.legs[0].shape, parent, 1e6);
    getElevation(json.trip.legs[0].shape, parent, local_seq, 1e6)
  });
}


planner = makeRouteOSRM;
var makeRoute = function(A, B, parent) {
  if (parent.straight) {
    parent.straight = true
    return makeRouteStraight(A, B, parent)
  }
  return planner(A, B, parent);
}

export var routes = new LinkedList(),
    // url_osrm_nearest = '//router.project-osrm.org/nearest/v1/walking/',
    // url_osrm_route = '//router.project-osrm.org/route/v1/walking/',
    url_osrm_nearest = 'https://osrm.witx.dk/nearest/v1/walking/',
    url_osrm_route = 'https://osrm.witx.dk/route/v1/walking/',
    icon_url_start = '/icon_green.png',
    icon_url_mid = '/icon_blue.png',
    icon_url_end = '/icon_red.png',
    routeSource = new VectorSource({
      // useSpatialIndex: true
    });
    export var routeLayer = new VectorLayer({
      title: 'Route',
      source: routeSource,
      type: 'overlay'
    }),
    styles = {
      route: new Style({
        stroke: new Stroke({
          width: 6, color: [40, 40, 200, 0.8]
        })
      }),
      icon_start: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: icon_url_start
        })
      }),
      icon_mid: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: icon_url_mid
        })
      }),
      icon_end: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: icon_url_end
        })
      })
    };
routeLayer.setZIndex(99999);
routeLayer.set('transparent', true)

var routePoint = new Feature(new Point([0,0]));
routePoint.setStyle([]);
routePoint.set('type', 'routepoint')
routeSource.addFeature(routePoint);

var route_collection = new Collection()

export var modify = new Modify({
    features: route_collection,
    hitDetection: routeLayer,
});

var target = document.getElementById('map');
modify.on(['modifystart', 'modifyend'], function (evt) {
  target.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
  if (evt.type === 'modifystart') {
    undo_stack.push(routes.getArray())
    redo_stack = []
  } else {
    updatePermalink()
  }
});

var overlaySource = modify.getOverlay().getSource();
overlaySource.on(['addfeature', 'removefeature'], function (evt) {
  target.style.cursor = evt.type === 'addfeature' ? 'pointer' : '';
});

var info = document.getElementById('info');
export function showInfo() {
  if (routes.length() == 0) {
    info.innerText = '';
    info.style.opacity = 0;
  } else {
    var l = formatLength(routes.getDistance());
    info.innerText = l;
    info.style.opacity = 1;
  }
  if (routes.length() > 0 || undo_stack.length > 0 || redo_stack.length > 0) {
    route_control.show();
  } else {
    route_control.hide();
  }
}

function clearRoute(save = true) {
  if (save) {
    undo_stack.push(routes.getArray())
    redo_stack = []
  }

  if (routes.isEmpty()) {
    undo_stack = [];
    redo_stack = [];
  }
  routes.clear();
  routeSource.clear();
  if (routePoint) {
    routeSource.addFeature(routePoint)
  }
  profile.full_hide()
  route_collection.clear();
  updatePermalink();
}

var working = false;

function undoRoute() {
  var l = undo_stack.length;
  if (l === 0 || working) {
    console.log("Clicked while zero")
    return
  }

  var r = undo_stack.pop()
  redo_stack.push(routes.getArray())
  var tmp_stack = undo_stack.slice()
  clearRoute(false);
  if (r.length === 0) {
    return
  }

  working = true

  addRoutePoints(r, 0, false);
  updatePermalink();
  undo_stack = tmp_stack.slice();
  console.log("Undoing from " + l + " to " + undo_stack.length)
}

function redoRoute() {
  var l = redo_stack.length;
  if (l === 0 || working) {
    return
  }
  var r = redo_stack.pop()
  undo_stack.push(routes.getArray())
  var tmp_stack = redo_stack.slice()
  clearRoute(false);
  if (r.length === 0) {
    return
  }

  working = true

  addRoutePoints(r, 0, false);
  updatePermalink();
  redo_stack = tmp_stack.slice();
}

refreshRoute = function() {
  console.log("refreshRoute")

  var r = routes.getArray()

  if (r.length === 0) {
    return
  }

  clearRoute();

  working = true

  addRoutePoints(r, 0, false);
  updatePermalink();
}

var getElevation = function(features, parent, local_seq, factor=1e5, resample = false) {

  var json_payload= {
     "encoded_polyline":features,
     "shape_format": factor == 1e5 ? "polyline5" : "polyline6",
     "height_precision": 2,
     "range":true,
  }

  if (resample) {
    json_payload.resample_distance = 10
  }

  var url = "https://valhalla.witx.dk/height"
  fetch(url, {method: "POST", body: JSON.stringify(json_payload)}).then(function(r) {
    return r.json();
  }).then(function(json) {
    if (local_seq < parent.seq) {
      return
    }

    var route = new Polyline({
      factor: factor
    }).readGeometry(json.encoded_polyline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });

    var coords = route.getCoordinates()
    var ele = json.range_height
    for (var i = 0; i < coords.length; i++) {
      coords[i].push(ele[i][1])
    }

    route.setCoordinates(coords, 'XYZ')

    createRoute2(route, parent, factor);
    parent.seq = local_seq
  });
}

function downloadGPX() {
  if (isTracking()) {
    logServer("Downloaded: " + window.location.href)
    window.dataLayer.push({
     'event': 'downloadRoute'
    });
  }

  if (routes.length() <= 0) {
    return;
  }
  var hiddenElement = document.createElement('a');

  var gpx_string = GetGPXFromFeatures();
  var index = 0;

  // Replace all except first
  gpx_string = gpx_string.replace(/<trkseg>/g, (item) => (!index++ ? item : ""));

  // Replace all except last
  var last = gpx_string.lastIndexOf('</trkseg>');
  var butLast = gpx_string.substring(0, last).replace(/<\/trkseg>/g, '');
  gpx_string = butLast + gpx_string.substring(last);

  hiddenElement.href = 'data:attachment/text,' + encodeURI(gpx_string);
  hiddenElement.target = '_blank';
  hiddenElement.download = 'route.gpx';
  hiddenElement.click();
}

function GetPolyLineFromFeatures() {
    var features = routes.getRouteFeatures()
    var format = new Polyline();

    var coords = []
    for (var i = 0; i < features.length; i++) {
      coords = coords.concat(features[i].getGeometry().getCoordinates())
    }
    var mls = new LineString(coords, 'XYZ')
    return mls
}

function GetGPXFromFeatures() {
    var features = routes.getRouteFeatures()
    var format = new GPX();

    var line_strings = []
    for (var i = 0; i < features.length; i++) {
      line_strings.push(features[i].get('geometry'))
    }
    var mls = new MultiLineString(line_strings)
    var feature = new Feature({
      geometry: mls
    });

    var feature_array = [feature]

    return format.writeFeatures(feature_array, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
  });
}

export var addRoutePointAfter = function(coord_street, parent){
  var idx = routes.indexOf(parent) + 1;
  var node = new RoutePoint(coord_street, null);
  node.straight = parent.straight
  undo_stack.push(routes.getArray())
  redo_stack = []
  routes.insertAt(node, idx);

  createFeature(coord_street, node, false);
  redrawRoute(node, BOTH);
}

var addRoutePointEnd = function(coord_street, straight_to, straight_from, save_history = true){
  var new_idx = routes.length();
  var node = new RoutePoint(coord_street, null);
  if (straight_from >= 0) {
    node.straight = straight_from
  }
  if (save_history) {
    undo_stack.push(routes.getArray())
    redo_stack = []
  }
  routes.add(node);
  var points_length = routes.length();

  createFeature(coord_street, node, false);

  if (points_length < 2) {
    updatePermalink();
    return;
  }
  var last_node = routes.getPrev(routes.getLastElement());
  if (!routes.isFirstElement(last_node)) {
    last_node.ignore_cb = true
    last_node.point_feature.setStyle(styles.icon_mid)
  }
  var last_point = last_node.point;
  //get the route
  var point1 = last_point.join();
  var point2 = coord_street.join();
  if (straight_to >= 0) {
    last_node.straight = straight_to
  }
  makeRoute(point1, point2, last_node);
}
  

export var addRoutePoints = function(coords, idx, save_history) {
  addRoutePointEnd(utils.to4326(coords[idx].coords), -1, coords[idx].straight,save_history);
  if (idx < coords.length - 1) {
    addRoutePoints(coords, idx + 1, save_history)
  } else {
    working = false;
  }
}

export var addRoutePoint = function(coord) {
  addRoutePointEnd(utils.to4326(coord), straight_mode, -1);
  updatePermalink()
  working = false;
}

var removeNode = function(node) {
  var prev = routes.getPrev(node);
  var next = routes.getNext(node);
  route_collection.remove(node.point_feature)
  routeSource.removeFeature(node.point_feature)
  if (node.route) {
    routeSource.removeFeature(node.route)
    node.route = null
  }
  undo_stack.push(routes.getArray())
  redo_stack = []
  if (prev || next) {
    routes.removeElement(node);
    if (prev) {
      if (prev.route) {
        routeSource.removeFeature(prev.route)
        prev.route = null
      }
      redrawRoute(prev, NEXT);
    } else if (next) {
      next.point_feature.setStyle(styles.icon_start);
    }
    if (next) {
      redrawRoute(next, PREV);
    } else if (prev && !routes.isFirstElement(prev)) {
      prev.point_feature.setStyle(styles.icon_end);
    }
  } else {
    clearRoute();
  }
}

var routeLayerFilter = function(layer) {
  if (layer == routeLayer) {
    return true;
  }
};

export var getRouteFeatureAtEvent = function(evt) {
  var feature = map.forEachFeatureAtPixel(map.getEventPixel(evt),
      function (feature, layer) {
        if (feature.get('type') == 'routepoint') {
          return null
        }
        return feature;
      },
      {layerFilter: routeLayerFilter,
       hitTolerance: isMobile ? 5 : 0})
  return feature;
}

export var initRouting = function() {

  var hover = new Hover({hitTolerance:30, layerFilter: routeLayerFilter });
  map.addInteraction(hover);
  hover.on("hover", function(e) {
    // Point on the line
    // var feature = GetPolyLineFromFeatures()
    var feature = e.feature.getGeometry()
    if (feature === undefined) {
      return
    }
    var c = feature.getClosestPoint(e.coordinate)
    drawPoint({ type: "over", coord: c });
    // Show profil
    var p = profile.showAt(e.coordinate);
    if (!p) {
      return
    }
    profile.popup(p[2]+" m");
  });
  hover.on("leave", function(e) {
    profile.popup();
    profile.showAt();
    drawPoint({});
  });

  map.getViewport().addEventListener('contextmenu', function (evt) {
    evt.preventDefault();
    var feature = getRouteFeatureAtEvent(evt)
    if (feature) {
      if (feature.get('type') == 'place') {
        var parent = feature.get('node');
        removeNode(parent);
      } else if (feature.get('type') == 'route') {
        var parent = feature.get('parent');
        if (isMobile) {
          parent.straight = !parent.straight
          redrawRoute(parent, NEXT)
          return
        }
        var coord_street = utils.to4326(map.getEventCoordinate(evt));
        addRoutePointAfter(coord_street, parent);
        if (isTracking()) {
          logServer("Insert middle: " + window.location.href)
        }
      }
    }
  })
};

const D_KEY = 68;
const H_KEY = 72;
const S_KEY = 83;
const P_KEY = 80;
const Q_KEY = 81;
const DOT_KEY = 190;

var straight_mode = false;

var prev_key = -1;
document.addEventListener('keydown', function(evt) {
  var gcdtext = document.getElementById('gcd-input-query');
  if (gcdtext === document.activeElement) {
    return;
  }
  var curr_key = evt.which;
  if (evt.which === S_KEY && prev_key === S_KEY) {
    clearRoute();
    evt.preventDefault();
    curr_key = -1;
  } else if (evt.which === D_KEY) {
    downloadGPX();
    evt.preventDefault();
  } else if (evt.which === Q_KEY) {
    straight_mode = !straight_mode;
    evt.preventDefault();
    map.render();
  }  else if (evt.which === DOT_KEY) {
    logServer("Saved: " + window.location.href)
    evt.preventDefault();
    map.render();
  } else if (evt.which === P_KEY) {
    if (planner === makeRouteOSRM) {
      console.log("Switching to valhalla")
      planner = makeRouteValhalla;
    } else {
      console.log("Switching to OSRM")
      planner = makeRouteOSRM;
    }
    refreshRoute();
    evt.preventDefault();
  } else if (evt.which === H_KEY) {
    var points = routes.getArray()
    if (points.length > 0) {
      var url = "https://www.google.dk/maps/dir/"
      for (var i = 0; i < points.length; i++) {
        var coord = utils.to4326(points[i].coords)
        url += coord[1] + "," + coord[0] + "/"
      }
      window.open(url,'_blank');
    }
    evt.preventDefault();
  }
  prev_key = curr_key;
});

export var REMOVE_NONE = 0
export var REMOVE_ONCE = 1
export var REMOVE_ALL  = 2

export var RouteControl = /*@__PURE__*/(function (Control) {

  var DEFAULT_BLUE = "rgba(0, 60, 136, 0.7)"
  var RED          = "rgba(102, 0, 0, 0.7)"
  var REDDER       = "rgba(255, 0, 0, 0.7)"
  var GREEN        = "rgba(0, 102, 0, 0.7)"

  function RouteControl(opt_options) {
    var options = opt_options || {};

    this.element = document.createElement('div');
    this.element.style.display = "block";
    this.element.className = 'mode-remove ol-unselectable ol-control';
    this.element.setAttribute("id", "remove_div");

    this.add_button = document.createElement('button');
    this.add_button.style = 'background-color: rgba(0,60,136, 0.7); width: 2.1em; height: 2.1em; display: inline-block'
    this.add_button.innerHTML = '<span class="glyphicon glyphicon-plus"></span>';

    this.remove_button = document.createElement('button');
    this.remove_button.style = 'background-color: rgba(0,60,136, 0.7); width: 2.1em; height: 2.1em; display: inline-block'
    this.remove_button.innerHTML = '<span class="glyphicon glyphicon-remove"></span>';

    this.undo_button = document.createElement('button');
    this.undo_button.style = 'background-color: rgba(0,60,136, 0.7); width: 2.1em; height: 2.1em; display: inline-block'
    this.undo_button.innerHTML = '<span class="glyphicon glyphicon-arrow-left"></span>';

    this.redo_button = document.createElement('button');
    this.redo_button.style = 'background-color: rgba(0,60,136, 0.7); width: 2.1em; height: 2.1em; display: inline-block'
    this.redo_button.innerHTML = '<span class="glyphicon glyphicon-arrow-right"></span>';

    this.download_button = document.createElement('button');
    this.download_button.style = 'background-color: rgba(0,60,136, 0.7); width: 2.1em; height: 2.1em; display: inline-block'
    this.download_button.innerHTML = '<span class="glyphicon glyphicon-download-alt"></span>';

    this.element.appendChild(this.download_button);
    this.element.appendChild(this.undo_button);
    this.element.appendChild(this.redo_button);
    this.element.appendChild(this.remove_button);
    this.element.appendChild(this.add_button);

    this.allow_add = false
    this.remove_state = REMOVE_NONE

    Control.call(this, {
      element: this.element,
      target: options.target
    });

    this.add_button.addEventListener('click', this.addPointClick.bind(this), false);
    this.remove_button.addEventListener('click', this.deletePoints.bind(this), false);
    this.undo_button.addEventListener('click', this.undo.bind(this), false);
    this.redo_button.addEventListener('click', this.redo.bind(this), false);
    this.download_button.addEventListener('click', this.download.bind(this), false);
  }

  if ( Control ) RouteControl.__proto__ = Control;
  RouteControl.prototype = Object.create( Control && Control.prototype );
  RouteControl.prototype.constructor = RouteControl;

  RouteControl.prototype.deletePoints = function deletePoints () {
    this.resetAdd()
    if (this.remove_state == REMOVE_NONE) {
      if (routes.isEmpty()) {
        clearRoute()
        return;
      }
      this.remove_state = REMOVE_ONCE
      this.remove_button.style["background-color"] = RED
    } else if (this.remove_state == REMOVE_ONCE) {
      this.remove_button.style["background-color"] = DEFAULT_BLUE
      this.remove_state = REMOVE_NONE
      clearRoute();
    }
    this.getMap().render();
  };

   RouteControl.prototype.addPointClick = function addPointClick () {
    this.resetRemoveState()
    if (this.allow_add) {
      this.resetAdd()
    } else {
      this.addPoint()
    }
  };

  RouteControl.prototype.addPoint = function addPoint () {
    this.allow_add = true
    this.add_button.style["background-color"] = GREEN
    this.getMap().render();
  };

  RouteControl.prototype.resetAdd = function resetAdd () {
    this.allow_add = false
    this.add_button.style["background-color"] = DEFAULT_BLUE
    this.getMap().render();
  };

  RouteControl.prototype.allowAdd = function allowAdd () {
    return this.allow_add
  };

  RouteControl.prototype.removeState = function removeState () {
    return this.remove_state
  };

  RouteControl.prototype.resetRemoveState = function resetRemoveState () {
    this.remove_state = REMOVE_NONE
    this.remove_button.style["background-color"] = DEFAULT_BLUE
  };

  RouteControl.prototype.undo = function undo () {
    undoRoute();
    this.getMap().render();
  };

  RouteControl.prototype.redo = function redo () {
    redoRoute();
    this.getMap().render();
  };

  RouteControl.prototype.download = function download () {
    downloadGPX();
    this.getMap().render();
  };

  RouteControl.prototype.show = function show () {
    this.remove_button.style.display = "inline-block";
    this.undo_button.style.display = "inline-block";
    this.redo_button.style.display = "inline-block";
    this.download_button.style.display = "inline-block";
  }

  RouteControl.prototype.hide = function hide () {
    this.remove_button.style.display = "none";
    this.undo_button.style.display = "none";
    this.redo_button.style.display = "none";
    this.download_button.style.display = "none";
  }

  return RouteControl;
}(Control));

Profile.prototype.full_hide = function() {
  this.element.style.display = "none";
};

Profile.prototype.full_show = function() {
  this.element.style.display = "block";
};

export var route_control = new RouteControl();
export var profile = new Profile();
profile.full_hide()

profile.on('show', function(e) {
  // enableAltitude(e.show)
})

function drawPoint(e) {
  if (!routePoint) return;
  if (e.type=="over"){
    // Show point at coord
    routePoint.setGeometry(new Point(e.coord));
    routePoint.setStyle(new Style({
       image: new CircleStyle({
           radius: 5,
           fill: new Fill({color: '#FF0000'}),
           stroke: new Stroke({color: '#0000FF', width: 1})
         }),
       stroke: new Stroke({
           color: 'rgba(0, 0, 255, 1.0)',
           width: 2
         })
      }));
  } else {
    // hide point
    routePoint.setStyle([]);
  }
};

profile.on(["over","out"], function(e) {
  if (e.type=="over") profile.popup(e.coord[2]+" m");
  drawPoint(e)
});

profile.on("click", function(e) {
  this.getMap().getView().setCenter(e.coord);
  this.getMap().getView().setZoom(16);
});

var parseRoute = function(route_string, straight_string) {
  var route_parts = route_string.split(',');
  var coords = [];
  for (var i = 0; i < route_parts.length; i += 2) {
    var x = parseFloat(route_parts[i]);
    var y = parseFloat(route_parts[i + 1]);
    coords.push(new HistoryPoint(utils.to3857([x, y]), false))
  }

  if (straight_string != null) {
    var straight_parts = straight_string.split(',');
    for (var i = 0; i < straight_parts.length; i++) {
      var straight_idx = parseInt(straight_parts[i]);
      if (straight_idx < 0 || straight_idx >= coords.length) {
        continue
      }
      coords[straight_idx].straight = true
    }
  }
  addRoutePoints(coords, 0, true)
}

export var parseRouteHash = function(hash) {
  // Remove the first character (i.e. the prepended "#").
  hash = hash.substring(1, hash.length);

  // This is where we will store our properties and values.
  var hashObj = {};

  // Split on the delimiter "&" and for each key/val pair...
  var route_value = null;
  var straight_value = null
  hash.split('&').forEach(function(q) {
    var key_val = q.split('=');
    var key = key_val[0];
    var value = key_val[1];
    if (key == "route") {
      route_value = value
    } else if (key == "straight") {
      straight_value = value
    }
  });

  if (route_value != null) {
    parseRoute(route_value, straight_value)
  }

  return hashObj;
}

export var handleRouteClick = function(event, longpress) {
  var feature = getRouteFeatureAtEvent(event.originalEvent);

  if (route_control.removeState() == REMOVE_ONCE) {
    if (feature && feature.get('type') == 'place') {
      var parent = feature.get('node');
      removeNode(parent);
      route_control.resetRemoveState()
      return
    }
  }

  if (isMobile && !longpress) {
    if (feature && feature.get('type') == 'route') {
      var coord_street = utils.to4326(map.getEventCoordinate(event.originalEvent));
      var parent = feature.get('parent');
      addRoutePointAfter(coord_street, parent);
      if (isTracking()) {
        logServer("Insert middle: " + window.location.href)
      }
      return
    }
  }

  if (!isMobile) {
    if (feature && feature.get('type') == 'route') {
      var parent = feature.get('parent');
      parent.straight = !parent.straight
      redrawRoute(parent, NEXT)
      return
    }
  }

  if (!isMobile || (route_control.allowAdd() && !longpress)) {
    addRoutePoint(event.coordinate);
    if (isTracking()) {
      window.dataLayer.push({
       'event': 'routeClick',
       'coordinate': utils.to4326(event.coordinate)
      });
      logServer("Insert new point: " + window.location.href)
    }
  }
}

export var createRouteFromGpx = async function(gpx) {
  var format = new GeoJSON();
  var geojson =  JSON.parse(format.writeFeature(gpx[0], {}));

  var options = {tolerance: 300, highQuality: false};
  var simplified = simplify(geojson, options)
  var coords_after = simplified.geometry.coordinates[0]

  console.log("Length after: " + coords_after.length)
  for (var i = 0; i < coords_after.length; i++) {
    var pt = coords_after[i]
    addRoutePoint(pt);
    await new Promise(r => setTimeout(r, 10));
  }

}
