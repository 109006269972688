import {transform} from 'ol/proj';

export var utils = {
  to4326: function(coord) {
    return transform([
      parseFloat(coord[0]), parseFloat(coord[1])
    ], 'EPSG:3857', 'EPSG:4326');
  },
  to3857: function(coord) {
    return transform([
      parseFloat(coord[0]), parseFloat(coord[1])
    ], 'EPSG:4326', 'EPSG:3857');
  }
};